<template>
  <nav class="md:pl-0 mt-2 md:text-center w-[100%] overflow-x-hidden" v-if="categories">
    <div class="overflow-scroll no-scrollbar sm:text-center">
      <NuxtLink
      v-for="(item, index) in categories"
      class="btn--cat" 
      :class="[{ 'ml-4' : index === 0 }, { 'mr-4' : index === categories.length - 1 }]"
      :to="`/blog/${item.slug}`">
        {{ item.title }}
      </NuxtLink>
    </div>
  </nav>
</template>

<script setup>
const supabase = useSupabaseClient()
const config = useRuntimeConfig()
const categories = ref(null)

const { data: cats } = await useAsyncData(
  'lp-bloc-cats', 
  async () => {
    const { data } = await supabase
    .from('article_categories')
    .select(`
      *
    `)
    .in('status', config.public.postStatus.split(','))
    return data
  }
)

categories.value = cats.value

</script>